import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
/* import specific icons */
import { faPersonDigging, faThumbsUp, faComment } from '@fortawesome/free-solid-svg-icons'
import ECharts from 'vue-echarts'
import "./style/global.css"

/* add icons to the library */
library.add(faPersonDigging, faThumbsUp, faComment);

const app = createApp(App);
app.config.warnHandler = () => null;
installElementPlus(app)
app.component('font-awesome-icon', FontAwesomeIcon).component('v-echarts', ECharts).use(store).use(router).mount('#app')
// app.component('v-echarts', ECharts).use(store).use(router).mount('#app')