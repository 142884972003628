<template>
    <div class="login-container" @keypress.enter="login">
        <div class="login-container-bg">
            <div class="form-wrapper">
                <div class="header">
                    管理平台
                </div>
                <div class="input-wrapper">
                    <div class="border-wrapper">
                        <input type="text" v-model="username" name="username" placeholder="请输入手机号" class="border-item" autocomplete="off" />
                    </div>
                    <div class="border-wrapper">
                        <input type="password" v-model="password" name="password" placeholder="请输入密码" class="border-item" autocomplete="off" />
                    </div>
                </div>
                <div class="action">
                    <div class="btn" @click="login">登录</div>
                </div>
                <!-- <div class="icon-wrapper"></div> -->
            </div>
        </div>
    </div>
</template>
<script>
    import HttpUtils from '@/components/utils/HttpUtils';
    export default({
        components:{},
        data() {
            return {
                username: "",
                password: ""
            };
        },
        mounted() {

        },
        methods: {
            login() {
                if(!this.username) {
                    this.$message.error("请输入登录手机号");
                    return;
                }
                if(!this.password) {
                    this.$message.error("请输入密码");
                    return;
                }
                console.log(this.username+", "+this.password);
                HttpUtils.POST("/api/v0.0.1/login", {"mobile":this.username, "password":this.password}).then(response=>{
					console.log(response);
					if(response.code == "200") {
						this.$message({
							message: response.msg,
							type: 'success'
						});
						this.$router.push({
                            path: "/home"
                        });
					} else {
                        let msg = response.msg;
                        if(response.data) {
                            msg = msg + response.data;
                        }
						this.$message.error(msg);
					}
				});
                
            }
        }
    });
</script>
<style lang="scss">
    * {
        padding: 0;
        margin: 0;
    }
    .login-container {
        overflow: hidden;
    }
    .login-container-bg {
        overflow: hidden;
        width: 100vw;
        height: 100vh;
        background: url(../assets/login-bg1.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .form-wrapper {
        margin: 0 auto;
        width: 300px;
        background-color: rgba(41, 45, 62, .5);
        color: #fff;
        border-radius: 2px;
        padding: 50px;
        box-shadow: 0 2px 6px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22) !important;
    }
    .form-wrapper .header {
        text-align: center;
        font-size: 35px;
        text-transform: uppercase;
        line-height: 100px;
    }
    .form-wrapper .input-wrapper input {
        background-color: rgb(41, 45, 62);
        border: 0;
        width: 100%;
        text-align: center;
        font-size: 15px;
        color: #fff;
        outline: none;
    }
    .form-wrapper .input-wrapper input::placeholder {
        text-transform: uppercase;
    }
    .form-wrapper .input-wrapper .border-wrapper {
        background-image: linear-gradient(to right, #e8198b, #0eb4dd);
        width: 100%;
        height: 50px;
        margin-bottom: 20px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .form-wrapper .input-wrapper .border-wrapper .border-item {
        height: calc(100% - 4px);
        width: calc(100% - 4px);
        border-radius: 30px;
    }
    .form-wrapper .action {
        display: flex;
        justify-content: center;
    }
    .form-wrapper .action .btn {
        width: 60%;
        text-transform: uppercase;
        border: 2px solid #0e92b3;
        text-align: center;
        line-height: 50px;
        border-radius: 30px;
        cursor: pointer;
    }
    .form-wrapper .action .btn:hover {
        background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
    }
    .form-wrapper .icon-wrapper {
        text-align: center;
        width: 60%;
        margin: 0 auto;
        margin-top: 20px;
        border-top: 1px dashed rgb(146, 146, 146);
        padding: 20px;
    }
    .form-wrapper .icon-wrapper {
        font-size: 20px;
        color: rgb(187, 187, 187);
        cursor: pointer;
        border: 1px solid #fff;
        padding: 5px;
        border-radius: 20px;
    }
    .form-wrapper .icon-wrapper i:hover {
        background-color: #0e92b3;
    }
</style>