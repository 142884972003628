<template>
  <div class="homeview" style="box-sizing: border-box; padding: 10px 10px 0px 10px;">
    <el-row style="box-sizing: border-box;">
      <!--本月充值金额-->
      <el-col :span="6">
        <el-card shadow="hover">
          <el-row>
            <el-col :span="14" style="display: flex; height: 30px; line-height: 30px; flex-direction: row; justify-content: flex-start;">
              <span class="card-title" style="height: 30px; line-height: 30px;">本月已充值</span>
            </el-col>
            <el-col :span="10"></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p style="font-size: 28px; margin-top: 20px; text-align: left;">
                <span>￥{{ homeData.monthCharge }}</span>
              </p>
            </el-col>
            <el-col :span="12" style="display: flex; flex-direction: row; justify-content: center;">
              <div class="right-icon"><font-awesome-icon size="2x" icon="fa-solid fa-wallet" /></div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!--本月总充值评论条数-->
      <el-col :span="6">
        <el-card shadow="hover">
          <el-row>
            <el-col :span="14" style="display: flex; height: 30px; line-height: 30px; flex-direction: row; justify-content: flex-start;">
              <span class="card-title" style="height: 30px; line-height: 30px;">本月充值评论</span>
            </el-col>
            <el-col :span="10"></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p style="font-size: 28px; margin-top: 20px; text-align: left;">
                <span>{{ homeData.monthCommentCharge }}</span>
              </p>
            </el-col>
            <el-col :span="12" style="display: flex; flex-direction: row; justify-content: center;">
              <div class="right-icon">
                <font-awesome-icon size="2x" icon="fa-solid fa-comments-dollar" />
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!--本月已使用评论条数-->
      <el-col :span="6">
        <el-card shadow="hover">
          <el-row>
            <el-col :span="14" style="display: flex; height: 30px; line-height: 30px; flex-direction: row; justify-content: flex-start;">
              <span class="card-title" style="height: 30px; line-height: 30px;">本月消耗评论</span>
            </el-col>
            <el-col :span="10"></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p style="font-size: 28px; margin-top: 20px; text-align: left;">
                <span>{{ homeData.monthUsedComment }}</span>
              </p>
            </el-col>
            <el-col :span="12" style="display: flex; flex-direction: row; justify-content: center;">
              <div class="right-icon">
                <font-awesome-icon size="2x" icon="fa-solid fa-comments" />
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
      <!--当前账户剩余评论条数-->
      <el-col :span="6">
        <el-card shadow="hover">
          <el-row>
            <el-col :span="14" style="display: flex; height: 30px; line-height: 30px; flex-direction: row; justify-content: flex-start;">
              <span class="card-title" style="height: 30px; line-height: 30px;">账户剩余评论</span>
            </el-col>
            <el-col :span="10"></el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <p style="font-size: 28px; margin-top: 20px; text-align: left;">
                <span>{{ homeData.accountBalanceComment }}</span>
              </p>
            </el-col>
            <el-col :span="12" style="display: flex; flex-direction: row; justify-content: center;">
              <div class="right-icon">
                <font-awesome-icon size="2x" icon="fa-solid fa-coins" />
              </div>
            </el-col>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <!--15天内评论消耗图表-->
    <el-row>
      <el-col :span="24">
        <el-card shadow="hover" style="height: 400px!important;">
          <v-chart class="chart" :option="option" style="height: 400px;" />
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// @ is an alias to /src
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faWallet,faCommentsDollar,faComments, faCoins } from '@fortawesome/free-solid-svg-icons'
library.add(faComment, faWallet, faCommentsDollar, faComments, faCoins);
import {
  use
} from 'echarts/core';
import {
  CanvasRenderer
} from 'echarts/renderers';
import {
  LineChart
} from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent
} from 'echarts/components';
import VChart from 'vue-echarts';
import {
  ref,
  defineComponent
} from 'vue';
import HttpUtils from '@/components/utils/HttpUtils';
use([
		CanvasRenderer,
		LineChart,
		TitleComponent,
		TooltipComponent,
		LegendComponent,
    GridComponent
	]);
export default {
  name: 'HomeView',
  components: {FontAwesomeIcon, VChart},
  data() {
    return {
      homeData: {},
      option: {
        title: {
          text: '15天内评论使用情况',
          left: 'left',
        },
        xAxis: {
          type: 'category',
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [],
            type: 'line'
          }
        ]
      }
    }
  },
  mounted() {
    this.query();
  },
  methods: {
    query() {
      console.log("查询开始");
      let dateArr = [];
      let dataArr = [];
      HttpUtils.POST("/api/v0.0.1/home/query", {}).then(response=>{
        console.log(response);
        if(response.code == "200") {
          this.homeData = response.data;
          if(this.homeData && this.homeData.usedCommentList && this.homeData.usedCommentList.length > 0) {
            let list = this.homeData.usedCommentList;
            for(var idx in list) {
              let temp = list[idx];
              console.log(temp);
              dateArr.push(temp.date);
              dataArr.push(temp.cnt);
              
            }
          }
          console.log(dateArr);
          console.log(dataArr);
          this.option.xAxis.data = dateArr;
          this.option.series[0].data = dataArr;
        }
      });
    }
  }
}
</script>
<style scoped lang="scss">
  .homeview {
    font-family: PingFang SC,Arial,Microsoft YaHei,sans-serif;
    font-size: 14px;
  }
  .homeview>.el-row>.el-col {
    padding: 10px;
  }
  .homeview .el-card {
    height: 180px;
  }
  .card-title {

  }
  .right-icon {
    color: #1890ff;
    text-align: center;
    background: #e8f4ff;
    border-radius: 50%;
    height: 60px;
    width: 60px;
    line-height: 70px;
    transform:translateY(10%);
  }
</style>
