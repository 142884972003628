import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import Login from "../views/Login.vue"
import Home from "../Home.vue"

const routes = [
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
			title: '管理平台 - 登录',
			keepAlive: false
		}
  },
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
			title: '管理平台',
			keepAlive: false
		}
  },
  {
    path: "", component: Login,
    meta: {
			title: '管理平台 - 登录',
			keepAlive: false
		}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
