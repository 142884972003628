<template>
	<ElConfigProvider :locale="locale">
	<el-row class="body-container">
		<el-col :span="leftNavSpan" class="dark-bg left-nav">
			<div class="left-nav-header">
				<div v-show="!isCollapse" style="display: flex; flex-direction: row; justify-content: center;">
					<el-avatar :size="40" :src="avatarUrl"></el-avatar>
					<div class="username">{{ userInfo.name }}</div>
				</div>
				<div v-show="isCollapse">
					<el-avatar :size="30" :src="avatarUrl"></el-avatar>
				</div>
			</div>
			<div class="line-xm"></div>
			<el-scrollbar style="height: 94%;">
			<el-menu default-active="1" background-color="#263544" text-color="#fff" active-text-color="#ffd04b"
				:collapse="isCollapse">
				<template v-for="(menu, index) in menus">
					<LeftNavMenu :menu="menu"></LeftNavMenu>
				</template>
			</el-menu>
		</el-scrollbar>
		</el-col>
		<el-col :span="rightNavSpan">
			<div class="header-menu">
				<div style="flex: 1" class="item-list float-left">
					<div class="item" @click="handleCollapse">
						<i :class="collapseIcon"></i>
					</div>
					<!-- <div class="item">
						<i class="el-icon-menu" title="标签"></i>
					</div> -->
				</div>
				<div style="flex: 10" class="item-list float-left">
					<!-- <el-breadcrumb separator="/">
						<el-breadcrumb-item>首页</el-breadcrumb-item>
						<el-breadcrumb-item>活动管理</el-breadcrumb-item>
						<el-breadcrumb-item>活动列表</el-breadcrumb-item>
						<el-breadcrumb-item>活动详情</el-breadcrumb-item>
					</el-breadcrumb> -->
				</div>
				<div style="flex:2; margin-right: 10px;" class="item-list float-center">
					<!-- <div class="item">
						<i class="el-icon-bell"></i>
					</div>
					<div class="item">
						<i class="el-icon-chat-dot-round"></i>
					</div>
					<div class="item">
						<i class="el-icon-message"></i>
					</div>
					<div class="item">
						<i class="el-icon-refresh"></i>
					</div> -->
					<el-popover
						placement="bottom"
						width="200px"
						trigger="hover" :visible="visible">
						<div class="user-info-pop" @mouseenter="showPop" @mouseleave="closePop">
							<div class="user-info-body">
								<el-row>
									<el-col :span="8">UID:</el-col>
									<el-col :span="16">{{ userInfo.uid }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">昵称:</el-col>
									<el-col :span="16">{{ userInfo.name }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">电话:</el-col>
									<el-col :span="16">{{ userInfo.mobile }}</el-col>
								</el-row>
								<div class="line-sm"></div>
								<el-row>
									<el-col :span="8">等级:</el-col>
									<el-col :span="16">{{ userInfo.level }}</el-col>
								</el-row>
							</div>
							<div class="line-sm"></div>
							<div class="user-info-footer">
								<el-button @click="closePop" size="mini">关闭</el-button>
								<el-button @click="handleUpdatePwd" size="mini" type="primary">修改密码</el-button>
								<el-button @click="logout" size="mini" type="danger">退出</el-button>
							</div>
						</div>
						<template #reference>
							<div @mouseenter="showPop" @mouseleave="closePop" class="item" style="display: flex; flex-direction: row; justify-content: flex-start; width: 100px;">
								<div class="avatar">
									<el-avatar shape="square" :size="40" :src="avatarUrl"></el-avatar>
								</div>
								<div class="username" style="font-size: 14px;">
									{{ userInfo.name }}
								</div>
							</div>
						</template>
					</el-popover>
				</div>
			</div>
			<div class="line-xxm"></div>
			<div class="main-box">
				<el-col class="main-box-panel" :span="24">
					<el-tabs style="height: 100%;" v-model="activeTabIdx" @tab-remove="handleTabRemove">
						<el-tab-pane key="home" label="主页" :closable="false" name="0">
							<template v-slot:label><i class="el-icon-house"></i>主页</template>
							<div class="tab-container">
								<statistics-board></statistics-board>
							</div>
						</el-tab-pane>
						<el-tab-pane
							:key="item.name"
							v-for="item in tabs"
							:label="item.title"
							:name="item.name"
							:closable="item.closable"
						>
							<template v-slot:label><i :class="item.icon"></i> {{item.title?item.title:""}}</template>
							<div class="tab-container">
								<component :is="item.component"></component>
							</div>
						</el-tab-pane>
					</el-tabs>
				</el-col>
			</div>
		</el-col>
	</el-row>
	<el-dialog title="密码修改" v-model="dialogFormVisible" :draggable="true">
		<el-form :model="updatePwdForm" label-width="80px" size="small">
			<el-row>
				<el-col :span="24">
					<el-form-item label="原密码">
						<el-input type="password" placeholder="请输入原密码" v-model="form.password"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="新密码">
						<el-input type="password" placeholder="请输入新密码" v-model="form.newPassword"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :span="24">
					<el-form-item label="确认密码">
						<el-input type="password" placeholder="请再次输入新密码" v-model="form.confirmPassword"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div class="dialog-footer">
			<el-button @click="dialogFormVisible = false">取 消</el-button>
			<el-button type="primary" @click="updatePassword">更 新</el-button>
		</div>
	</el-dialog>
</ElConfigProvider>
</template>

<script>
	import LeftNavMenu from "./components/LeftNavMenu.vue"
	import StatisticsBoard from "./views/HomeView.vue";
	import { defineAsyncComponent } from 'vue'
	import HttpUtils from "@/components/utils/HttpUtils";
	import { ElConfigProvider } from 'element-plus';
	import { ref } from 'vue';
	import zhLocale from 'element-plus/lib/locale/lang/zh-cn';
	import store from './store/index';

	export default {
		name: 'Home',
		components: {
			LeftNavMenu, ElConfigProvider, StatisticsBoard
		},
		setup() {
			const locale = ref(zhLocale);
			return {
				locale
			};
		},
		created() {
			this.height = window.innerHeight - 70;
		},
		mounted() {
			HttpUtils.POST("/api/v0.0.1/customer/query", {}).then(response=>{
                if(response.code == "200") {
                    this.userInfo = response.data;
					if(this.userInfo.id != 1) {
						this.initMenus(false);
					} else {
						this.initMenus(true);
					}
                }
            });
		},
		data() {
			return {
				userInfo:{},
				dialogFormVisible: false,
				form: {},
				visible: false,
				height: 800,
				activeTabIdx: "0",
				isCollapse: false,
				leftNavSpan: 3,
				rightNavSpan: 21,
				collapseIcon: "el-icon-s-fold",
				avatarUrl: "http://47.108.184.31/file/avatarUrl.jpg",
				tabs:[],
				menus:[]
			}
		},
		//提供给LeftNavMenu组件新增标签栏使用
		provide: function () {
			return {
				addTab: this.addTab
			}
		},
		methods: {
			//权限暂时先写死
			initMenus(isAdmin) {
				let menus = [{
					name: "业务管理",
					uid:1,
					icon: "el-icon-location",
					isShow: true,
					children:[{
						name: "任务管理",
						uid:"11",
						icon: "el-icon-monitor",
						url:"/approbot/order-task/OrderTaskList.vue",
						component: "OrderTaskList",
						isShow: true	//权限暂时写死
					}, {
						name: "明细列表",
						uid:12,
						icon: "el-icon-tickets",
						url: "/approbot/order-task/TaskProcessedClientLogList.vue",
						component: "TaskProcessedClientLogList",
						isShow: true	//权限暂时写死
					}, {
						name: "标签管理",
						uid:13,
						icon: "el-icon-collection-tag",
						url: "/approbot/work-tag/WorkTagList.vue",
						component: "WorkTagList",
						isShow: true	//权限暂时写死
					}, {
						name: "智能评论管理",
						uid:14,
						icon: "el-icon-chat-dot-round",
						url: "/approbot/app-comment/AppCommentList.vue",
						component: "AppCommentList",
						isShow: true	//权限暂时写死
					}, {
						name: "客户管理",
						uid:15,
						icon: "el-icon-user",
						url: "/approbot/customer/CustomerList.vue",
						component: "CustomerList",
						isShow: isAdmin	//权限暂时写死
					}, {
						name: "充值管理",
						uid:16,
						icon: "el-icon-coin",
						url: "/approbot/order-detail/OrderDetailList.vue",
						component: "OrderDetailList",
						isShow: true	//权限暂时写死
					}, {
						name: "账号管理",
						uid:17,
						icon: "el-icon-user",
						url: "/approbot/account/AccountList.vue",
						component: "AccountList",
						isShow: isAdmin	//权限暂时写死
					}, {
						name: "提现管理",
						uid:18,
						icon: "el-icon-user",
						url: "/approbot/cash-log/CashTransferLogList.vue",
						component: "CashTransferLogList",
						isShow: isAdmin	//权限暂时写死
					}]
				}, {
					name: "系统配置",
					uid: 2,
					icon: "el-icon-menu",
					isShow: isAdmin,
					children: [{
						name: "风控管理",
						uid:21,
						icon: "el-icon-magic-stick",
						url: "/approbot/risk-control/RiskControlList.vue",
						component: "RiskControlList",
						isShow: isAdmin	//权限暂时写死
					}, {
						name: "选项2",
						uid:22,
						icon: "el-icon-location"
					}, {
						name: "选项3",
						uid:23,
						icon: "el-icon-location"
					}, {
						name: "选项4",
						uid:24,
						icon: "el-icon-location"
					}, {
						name: "选项5",
						uid:25,
						icon: "el-icon-location"
					}, {
						name: "选项6",
						uid:26,
						icon: "el-icon-location"
					}, {
						name: "选项7",
						uid:27,
						icon: "el-icon-location"
					}, {
						name: "选项8",
						uid:28,
						icon: "el-icon-location"
					}, {
						name: "选项9",
						uid:29,
						icon: "el-icon-location"
					}, {
						name: "选项10",
						uid:210,
						icon: "el-icon-location"
					}, {
						name: "选项11",
						uid:211,
						icon: "el-icon-location"
					}, {
						name: "选项12",
						uid:212,
						icon: "el-icon-location"
					}]
				}, {
					name: "导航三",
					uid: 3,
					icon: "el-icon-document",
					children: []
				}, {
					name: "导航四",
					uid: 4,
					icon: "el-icon-setting",
					children: []
				}, {
					name: "导航五",
					uid: 5,
					icon: "el-icon-document",
					children: []
				}]
				this.menus = menus;
			},
			handleCollapse() {
				this.isCollapse = !this.isCollapse;
				if (this.isCollapse) {
					this.leftNavSpan = 1;
					this.rightNavSpan = 23;
					this.collapseIcon = "el-icon-s-unfold";
				} else {
					this.leftNavSpan = 3;
					this.rightNavSpan = 21;
					this.collapseIcon = "el-icon-s-fold";
				}
			},
			addTab(menu) {
				var tab = null;
				for(var idx in this.tabs) {
					var existTab = this.tabs[idx];
					if(existTab.name == menu.uid) {
						tab = existTab;
						break;
					}
				}
				if(!tab) {
					console.log(menu.url);
					// var component = () => import("./views/"+menu.url);
					// var component = ()=>import("./views/approbot/order-task/OrderTaskList.vue");
					//上面的方式不能用了，需要用下面这种方式来实现异步加载
					var component = defineAsyncComponent(() =>
						import("./views"+menu.url)
					);
					console.log(component);
					this.$options.components[menu.component] = component;
					console.log(this.$options.components[menu.component]);
					tab = {
						title: menu.name,
						icon: menu.icon,
						name: menu.uid,
						component: component,
						closable: true
					}
					this.tabs.push(tab);
				}
				this.activeTabIdx = tab.name;
			},
			//name为被删除的tab的name
			handleTabRemove(name) {
				//console.log("删除tab为"+name+"的标签");
				for(var idx in this.tabs) {
					var tab = this.tabs[idx];
					if(tab.name == name) {
						this.tabs.splice(idx, 1);
						if(this.activeTabIdx == name) {
							this.activeTabIdx = this.tabs[idx]?this.tabs[idx].name:this.tabs[idx-1].name;
						}
						break;
					}
				}
			},
			showPop() {
				this.visible = true;
			},
			closePop() {
				this.visible = false;
			},
			logout() {
				store.commit('setToken', "");
                window.localStorage.setItem('token', "");
				window.location.href = "/login";
			},
			handleUpdatePwd() {
				this.form = {};
				this.dialogFormVisible = true;
			},
			updatePassword() {
				if(!this.form.password) {
					this.$message.error("请输入原密码");
                    return;
				}
				if(!this.form.newPassword) {
					this.$message.error("请输入新密码");
                    return;
				}
				if(!this.form.confirmPassword) {
					this.$message.error("请输入确认密码");
                    return;
				}
				HttpUtils.POST("/api/v0.0.1/home/update-password", this.form).then(response=>{
					if(response.code == "200") {
						this.$message({
							message: response.msg,
							type: 'success'
						});
						this.dialogFormVisible = false;
						this.updatePwdForm = {};
					} else {
						this.$message.error(response.msg);
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	body {
		overflow: hidden;
		margin: 0px;
	}

	//滚动条整体部分
    *::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    //滚动条轨道两端按钮，允许通过点击微调小方块的位置
    *::-webkit-scrollbar-button {
        display: none;
    }
    //滚动条里面的小方块，能向上向下移动（或左右移动）
    *::-webkit-scrollbar-thumb {
        background: rgba(144,147,153,0.3);
        cursor: pointer;
        border-radius: 4px;
    }
    //两个滚动条交汇处的边角
    *::-webkit-scrollbar-corner {
        display: none;
    }
    //两个滚动条交汇处用于通过拖动调整元素大小的小控件
    *::-webkit-resizer {
        display: none;
    }

	#app {
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		text-align: center;
		color: #2c3e50;
		/* margin-top: 60px; */
		margin-top: 0px;
		overflow: hidden;
	}

	.body-container {
		background-color: #f1f1f1;
		// background-color: #fff;
	}

	.dark-bg {
		background-color: #263544 !important;
		color: #fff;
	}

	.el-submenu .el-menu-item {
		min-width: 0 !important;
	}

	.el-menu {
		text-align: left !important;
		border-right: 0px !important;
		margin-right: 6px;
		box-sizing: border-box;
	}

	.left-nav {
		height: calc(100vh);
		overflow-x: hidden;
		overflow-y: hidden;
		transition-property: max-width, flex;
		transition-duration: .25s, .25s;
		transition-timing-function: ease;
		box-shadow: 0 2px 6px rgba(0, 0, 0, .25), 0 10px 10px rgba(0, 0, 0, .22) !important;
		// box-shadow: 0 1px 4px rgb(0 21 41 / 8%);

		.left-nav-header {
			height: 40px;
			padding: 8px;
			line-height: 40px;
			display: flex;
			justify-content: center;

			div {
				display: flex;
				flex-direction: row;
				justify-content: space-around;

				.username {
					margin-left: 10px;
				}
			}
		}

		.line-sm {
			height: 1px;
			transform-origin: 0 0;
			transform: scaleY(.1);
			border-bottom: 1px solid #dee2e6;
		}

		.el-avatar {
			border: 2px solid #fff;
		}
	}

	.line-sm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.1);
		border-bottom: 1px solid #dee2e6;
	}

	.line-xm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.05);
		border-bottom: 1px solid #dee2e6;
	}

	.line-xxm {
		height: 1px;
		transform-origin: 0 0;
		transform: scaleY(.01);
		border-bottom: 1px solid #dee2e6;
	}

	.header-menu {
		background-color: #fff;
		// border-bottom: 1px solid #dee2e6;
		// box-shadow: 0 2px 4px 0 rgb(43 43 43 / 10%);
		height: 56px !important;
		line-height: 56px !important;
		display: flex;
		flex-direction: row;
		border: 0px;
		.item-list {
			display: flex;
			flex-direction: row;

			.item {
				cursor: pointer;
				min-width: 50px;
				text-align: center;
			}

			.item .avatar {
				padding: 8px;
			}

			.el-breadcrumb {
				line-height: 58px !important;
				font-size: 16px;
				letter-spacing: 2px;

				.el-breadcrumb__separator {
					margin: 0px 15px;
				}
			}
		}
	}

	.float-center {
		justify-content: center;
	}

	.float-left {
		justify-content: flex-start;
	}

	.float-right {
		justify-content: flex-end;
	}
	.main-box {
		.main-box-panel {
			height: calc(100vh);
			width: calc(100vw);
			// border: 1px solid #dee2e6;
			// border-radius: 5px;
			// height: calc(100vh);
			// box-shadow: 0px 0px 4px 3px rgb(43 43 43 / 10%);	//0px 0px表示四周都有阴影
		}
	}
	.el-tabs__header {
		background-color: #fff!important;
		border-bottom: 1px solid #dee2e6!important;
		// box-shadow: 0 1px 2px 0 rgb(43 43 43 / 10%)!important;
		box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
		padding: 0px 20px!important;
		margin-bottom: 0px!important;
	}
	.el-tabs__content {
		height: calc(87vh);
		box-sizing: border-box;
		// padding: 10px;
		overflow-y: auto!important;
	}
	.el-tab-pane {
		height: 100%;
	}
	.tab-container {
		// margin: 0px 8px;
		// box-sizing: border-box;
		// height: 100%;
		// border-radius: 5px;
		// background-color: #fff;
		// box-shadow: 0px 0px 4px 2px rgba(238, 238, 238, 0.55);
		// overflow-y: auto;
		// margin-bottom: 30px;
	}
	.user-info-pop {
		width: 100%;
		box-sizing: border-box;
	}
	.user-info-body {
		width: 100%;
		margin-bottom: 10px;
	}
	.user-info-footer {
		margin-top: 10px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
	}
	.user-info-body .el-row .el-col{
		line-height: 25px!important;
		font-size: 12px!important;
		padding: 5px;
	}
	.user-info-body .el-row .el-col:nth-child(odd) {
		text-align: right!important;
	}
	.user-info-body .el-row .el-col:nth-child(even) {
		text-align: left!important;
	}
</style>
