<template>
    <el-submenu v-if="menu.isShow && menu.children && menu.children.length > 0" :index="menu.uid+''">
        <template v-slot:title>
            <i :class="menu.icon"></i>
            <span slot="title">{{menu.name}}</span>
        </template>
        <template v-for="child in menu.children">
            <LeftNavMenu :menu="child"></LeftNavMenu>
        </template>
    </el-submenu>
    <el-menu-item v-else-if="menu.isShow" @click="addTab(menu)" :index="menu.uid+''">
        <i :class="menu.icon" style="height: 20px; line-height: 20px;"></i>
        <span slot="title" style="margin-left: 5px;height: 20px; line-height: 20px;">{{menu.name}}</span>
    </el-menu-item>
</template>
<script>
export default {
    name: "LeftNavMenu",
    props:["menu"],
    inject: ["addTab"],
    methods: {
    }
}
</script>

